<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <div style="width: 250px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Advance and Loans
        </h1>
      </div>
    </div>
    <div class="px-3">
      <div class="mt-8">
        <Tab
          :tabs="tabs"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
        />
      </div>
      <Advance v-if="currentTab === 'Advance'" />
      <Loans v-if="currentTab === 'Loans'" />
      <ConsolidatedAdvance
        v-if="currentTab === 'Consolidated Advance and Loans'"
      />
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Advance from "./Advance";
import Loans from "./Loans";
import ConsolidatedAdvance from "./ConsolidatedAdvance";

export default {
  name: "AdvanceLoans",
  components: {
    Tab,
    Advance,
    Loans,
    ConsolidatedAdvance
  },
  data() {
    return {
      currentTab: "Advance",
      tabs: [],
    };
  },
  created() {
    if (process.env.NODE_ENV === 'development') {
      this.tabs = ['Advance', 'Loans', 'Consolidated Advance and Loans']
    }
    else {
      this.tabs = ['Advance', 'Loans']
    }
  }
};
</script>
